<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ects_coordinator_course_definitions')"
                        :is-filter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ects_coordinator_course_definitions')"
                              :is-filter="false" />
            </template>
            <b-row v-for="(course, index) in courses" :key="index">
                <b-col md="12">
                    <b-button variant="link"
                              v-b-toggle="`course-${index}`"
                    ><b>{{course.code+' - '+getLocaleText(course, 'name')}}</b></b-button>
                    <b-collapse :id="`course-${index}`" visible accordion="my-accordion">
                        <b-col md="12" class="ml-4" v-if="checkPermission('ects_updatecoursebycoordinator')">
                            <b-button variant="link" @click="openDefinitions(course, 'update-form')"><span class="text-black-50">{{ '1. '+$t('ects_course_definitions') }}</span></b-button>
                        </b-col>
                    </b-collapse>
                </b-col>
            </b-row>

            <CommonModal ref="updateModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="closeDefinitions">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="courseData" :course="courseData" @closeModal="closeDefinitions" :is="isPage"></component>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import CommonModal from "@/components/elements/CommonModal"
// Pages

// Services
import EctsService from "@/services/EctsService"

// Others

// Pages
import UpdateForm from "./UpdateForm"


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        UpdateForm,
    },
    metaInfo() {
        return {
            title: this.$t('ects_coordinator_course_definitions')
        }
    },
    data() {
        return {
            courses: null,
            courseData: null,
            updateModalTitle: null,
            isPage: null
        }
    },
    created() {
        this.getCourses()
    },
    methods: {
        getCourses(){
            EctsService.getAll().then(response => {
                this.courses = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        openDefinitions(course, page){
            this.isPage = page
            this.courseData = course
            this.updateModalTitle = course.code+' - '+this.getLocaleText(course, 'name')
            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeDefinitions(){
            this.isPage = null
            this.courseData = null
            this.$refs.updateModal.$refs.commonModal.hide()
        }
    }
}
</script>

